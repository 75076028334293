const Jumbotron = () => {
  return (
    <div className="jumbotron">
      <div className="jumbotron__holder">
        <h1 className="jumbotron__title">ManageBac Diploma Analytics</h1>
      </div>
    </div>
  );
};

export default Jumbotron;
